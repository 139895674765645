import { SnackbarProvider } from "notistack";

import { ConfirmDialogProvider } from "../contexts/confirm-dialog";
import { NestedDialogProvider } from "../contexts/nested-dialog";
import QueryClientProvider from "../components/DefaultQueryClientProvider";
import { PromiseDialogProvider } from "@/components/PromiseDialog";

/* Don't put Strict in DefaultLayout.
 * If the create po page is wrapped in StrictMode,
 * it shows two rows when a poitem is created. */
const DefaultLayout = (callback) => {
  return (
    <QueryClientProvider>
      <SnackbarProvider
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        maxSnack={4}
      >
        <ConfirmDialogProvider>
          <PromiseDialogProvider>
            <NestedDialogProvider>{callback}</NestedDialogProvider>
          </PromiseDialogProvider>
        </ConfirmDialogProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
};

export default DefaultLayout;
